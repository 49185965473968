import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Album from '../components/Album';
import Copyright from '../components/Copyright';

export default function Index() {
  return (
    <Album></Album>
  );
}