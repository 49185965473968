import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));
  


export default function ApartmentCard(props) {
    const classes = useStyles();
    return (
        <Card className={classes.card} variant="outlined">
        <CardMedia
          className={classes.cardMedia}
          image={props.image1url}
        />
        <CardMedia
          className={classes.cardMedia}
          image={props.image2url}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.town + " " + props.bedroomcount + " Bedroom"}
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
             Address: {props.address}
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            Bedrooms: {props.bedroomcount}
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            Bathrooms: {props.bathcount}
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            Floor: {props.floor}
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            Rent: {props.rent}
          </Typography>
        </CardContent>
        <CardActions>
          <Button 
            size="medium" 
            variant="contained" 
            color="primary" 
            href={"https://constitutionmgmt.typeform.com/to/GZnnQmye#address=" + props.address }
            >
            Apply
          </Button>
        </CardActions>
      </Card>
    )
}